import React from "react";
import loadable from '@loadable/component'

const MasonryFiveImageHorizontal = loadable(() =>  import("./masonry-five-image-horizontal"));
const MasonryVertical = loadable(() =>  import("./masonry-vertical"));

const Start = ({ module }) => {
    const { gallery } = module;
    const { display } = gallery;

    switch(display) {
        case "masonry-five-image-horizontal":
            return <MasonryFiveImageHorizontal module={gallery} />;
        case "masonry-vertical":
            return <MasonryVertical module={gallery} />;
        default:
            return null;
    }
};

export default Start;